import React from "react";
import NoteContext, {NoteModes} from '../NoteContext';
import {formatDate} from './minors/card';

function NoteNew(props) {
	const [typing, settyping] = React.useState(false);
	const [textareaStyle, settextareaStyle] = React.useState({
		textAlign: "justfiy",
	});
	const [content, setcontent] = React.useState('')
	const [title, settitle] = React.useState('');
	const [tag, settag] = React.useState('');
	const [date, setdate] = React.useState(formatDate(Date.now()));
	const [id, setid] = React.useState(-1);
	const {noteMode, setNoteModes} = React.useContext(NoteContext);
	
	function newnoteadded() {
		props.add(title,content,date,tag,id);
		settitle('');
		setcontent('');
		setNoteModes(NoteModes.Normal);
	}

	function textareaHeight(e) {
		settextareaStyle({
			height: e.target.scrollHeight + "px",
			textAlign: "justfiy",
			maxHeight: "500px",
		});
	}
	function titlechanged(e) {
		settitle(e.target.value);
	}
	function contentchanged(e) {
		settyping(true);
		setcontent(e.target.value);
	}
	function tagchanged(e) {
		settag(e.target.value);
	}

	return (
<div className="newnote">
	<div className="col s12 m12 l12">
		<div className="card">
			<div className="card-image">
				<span className="card-title"></span>
			</div>
			<div className="card-content">
				<a className="grey-text right">{date}  <strong>#{id}</strong></a>
				<p>
					<textarea
						value={tag}
						onClick={tagchanged}
						onChange={tagchanged}
						style={textareaStyle}
						placeholder="Tag"
						id="enternotetag"
						className="materialize-textarea"
					></textarea>
				</p>
				<div className="input-title">
					<input id="enternotetitle" type="note" onChange={titlechanged} value={title} placeholder="Title" />
				</div>
				<p>
					<textarea
						value={content}
						onClick={contentchanged}
						onChange={contentchanged}
						style={textareaStyle}
						onInput={textareaHeight}
						placeholder="Body"
						id="enternotebody"
						className="materialize-textarea"
					></textarea>
				</p>
			</div>
			<a onClick={newnoteadded} className="btn-floating btn-large halfway-fab waves-effect waves-dark blue">
				<i className="material-icons">save</i>
			</a>
		</div>
	</div>
</div>
	);
}

export default NoteNew;
