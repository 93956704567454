import React from 'react';

export const NoteModes = {
	Normal: 'normal',
	Creating: 'creating',
	Deleting: 'deleting',
};

const NoteContext = React.createContext({
	noteMode: NoteModes.Normal,
	setNoteModes: () => {},
});
export default NoteContext;