import React from "react";
import NoteContext, {NoteModes} from "../../NoteContext";

export function formatDate(timestamp) {
	if(! Number.isInteger(timestamp)){
		return "unknown"
	}
	const date = new Date(timestamp)
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function createCard(content, deletenote, editnote) {
	if (content.id == undefined || content.id == "NULL") {
		console.log("not creating card");
		return null;
	} else {
		console.log("creating card\ncontent = " + content.note + "\nid = " + content.id + "\ndate = " + content.date + "\ntag = " + content.tag + "\ntitle = " + content.title + "");
		return (
<Card
	del={deletenote}
	edit={editnote}
	id={content.id}
	key={content.id}
	date={formatDate(content.date)}
	tag={content.tag}
	title={content.title}
	content={content.note}
/>
		);
	}
}

function Card(props) {
	const { noteMode, setNoteModes } = React.useContext(NoteContext);
	console.log("noteMode = " + noteMode);
	function beginEditing() {
		setNoteModes(NoteModes.Editing);
		props.edit(props.id);
	}
	function taskDelete() {
		props.del(props.id);
	}
	return (
<div>
	<div className="col s8 offset-s2 m8 offset-m2">
		<div className="card white lighten">
			<div className="card-content black-text">
				<a className="grey-text">#{props.tag}</a><a className="grey-text right">{props.date}  <strong>#{props.id}</strong></a>
				<span className="card-title">{props.title}</span>
				<p className="text-justify">{props.content}</p>
			</div>
			<div className="card-action black-text">
				<a
					style={{
						display: "flex",
						flexDirection: "row-reverse",
						marginRight: "5px",
					}}
					className="black-text text-lighten-3"
					href="#"
				>
					{noteMode === NoteModes.Deleting ? <i
						style={{ marginRight: "10px", fontSize: "1.8em" }}
						onClick={taskDelete}
						className="small material-icons tooltipped"
						data-position="top"
						data-tooltip="Delete"
					>delete</i>:null}
					{noteMode === NoteModes.Normal ? <i
						style={{ marginRight: "10px", fontSize: "1.8em" }}
						className="small material-icons tooltipped"
						data-position="top"
						data-tooltip="Edit"
						onClick={beginEditing}
					>edit</i>:null}
				</a>
			</div>
		</div>
	</div>
</div>
	);
}

export default createCard;
