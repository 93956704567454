import React from "react";
import NoteContext, {NoteModes} from '../NoteContext';

function Heading() {
	const { noteMode, setNoteModes } = React.useContext(NoteContext);
	// function updatenotemode(mode) {
	//   setNoteModes(mode);
	//   console.log("noteMode = " + noteMode);
	// }
	function toggleDeleteMode(){
		if(noteMode === NoteModes.Deleting){
			setNoteModes(NoteModes.Normal)
		}else{
			setNoteModes(NoteModes.Deleting)
		}
	}
	return (
<div>
	<div className="navbar-fixed white grey-text text-darken-2">
		<nav>
			<div className="white black-text text-darken-2 nav-wrapper">
				<div className="search">
					<div className="input-field">
						<input id="search" type="search" required />
						<label className="label-icon" htmlFor="search">
							<i className="material-icons black-text search-icon">search</i>
						</label>
						<i className="material-icons close-icon">close</i>
					</div>
				</div>
				<ul className="white black-text text-darken-2 right">
					<li>
						<a className="black-text text-darken-2" onClick={() => setNoteModes(NoteModes.Creating)}>
							<i className="material-icons">add</i>
						</a>
					</li>
					<li>
						<a className="black-text text-darken-2" onClick={() => toggleDeleteMode()}>
							<i className="material-icons">delete</i>
						</a>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</div>
	);
}

export default Heading;
