import React from "react";
import NoteContext, {NoteModes} from './NoteContext';
import Header from "./components/header";
import createCard from "./components/minors/card";
import NoteNew from "./components/noteNew";

function App() {
	const storedNotesRaw = localStorage.getItem("notes");
	const storedNotes = (storedNotesRaw === "")? []:JSON.parse(storedNotesRaw);
	const storedNotesLength = (storedNotes == null || storedNotes == undefined)? 0:storedNotes.length;
	console.log("noteCount = "+storedNotesLength);


	const [notes, setnotes] = React.useState(() => {
		if (storedNotesLength > 0) {
			return storedNotes;
		}
		return [];
	});

	function saveNotesToDB(oldNotes, id) {
		if (notes !== oldNotes) {
			const savedNotes = notes.filter((note) => {
				return note.id !== -1;
			});
			const savingdata = JSON.stringify(savedNotes);
			console.log("Saving notes: " + savingdata);
			localStorage.setItem("notes", savingdata);
		} else {
			console.log(id);
			setnotes((prevNotes) => {
				oldNotes = prevNotes;
				return prevNotes.filter((note) => note.id !== id);
			});
			setTimeout(() => {
				saveNotesToDB(oldNotes, id);
			}, 1000);
		}
	}
	
	const [noteMode, setNoteModes] = React.useState(NoteContext.Normal);
	// const {noteMode, setNoteModes} = React.useContext(NoteContext);

	function addNote(title, content, date, tag, newId) {
		setnotes((prev) => {
			return [
				...prev,
				{
					id: newId,
					title: title,
					note: content,
					date: date,
					tag: tag,
				},
			];
		});
		setTimeout(saveNotesToDB, 500);
	}
	function editnotebegin(id) {
		console.log("editing" + id+" mode "+noteMode);
	}
	function deleteNote(id) {
		var oldnotes;
		setnotes((prevNotes) => {
			oldnotes = prevNotes;
			return prevNotes.filter((note) => note.id !== id);
		});
		saveNotesToDB(oldnotes, id);
	}
	return (
<NoteContext.Provider value={{ noteMode, setNoteModes }}>
	<div>
		<Header />
		{noteMode === NoteModes.Creating ? <NoteNew add={addNote}/> : null}
		<div className="row">
			{notes.map((note) => createCard(note, deleteNote, editnotebegin))}
		</div>
	</div>
</NoteContext.Provider>
	);
}

export default App;
